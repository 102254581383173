import {
  ACTIONS,
  Company,
  Instructions,
  InternalSource,
  PlaygroundAnalysisAction,
  ResearchPlanParts,
  SectionsState,
  Source,
  SuggestedQuestion,
  ValueChain,
} from '../../types';

export const clearAnalysis = (): PlaygroundAnalysisAction<SectionsState> => {
  return {
    type: ACTIONS.CLEAR_ANALYSIS,
  };
};

export const companiesInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['companies']> => {
  return {
    type: inProgress ? ACTIONS.COMPANIES_PENDING : ACTIONS.COMPANIES_COMPLETED,
  };
};

export const companiesSuccess = (data: Company[]): PlaygroundAnalysisAction<SectionsState['companies']> => {
  return {
    type: ACTIONS.COMPANIES_SUCCESS,
    payload: { data },
  };
};

export const companiesFailed = (): PlaygroundAnalysisAction<SectionsState['companies']> => {
  return {
    type: ACTIONS.COMPANIES_FAILED,
  };
};

export const connectionsInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['connections']> => {
  return {
    type: inProgress ? ACTIONS.CONNECTIONS_PENDING : ACTIONS.CONNECTIONS_COMPLETED,
  };
};

export const connectionsSuccess = (data: string[]): PlaygroundAnalysisAction<SectionsState['connections']> => {
  return {
    type: ACTIONS.CONNECTIONS_SUCCESS,
    payload: { data },
  };
};

export const connectionsFailed = (): PlaygroundAnalysisAction<SectionsState['connections']> => {
  return {
    type: ACTIONS.CONNECTIONS_FAILED,
  };
};

export const suggestedQuestionsInProgress = (
  inProgress = true
): PlaygroundAnalysisAction<SectionsState['suggested_questions']> => {
  return {
    type: inProgress ? ACTIONS.SUGGESTED_QUESTIONS_PENDING : ACTIONS.SUGGESTED_QUESTIONS_COMPLETED,
  };
};

export const suggestedQuestionsSuccess = (
  data: SuggestedQuestion[]
): PlaygroundAnalysisAction<SectionsState['suggested_questions']> => {
  return {
    type: ACTIONS.SUGGESTED_QUESTIONS_SUCCESS,
    payload: { data },
  };
};

export const suggestedQuestionsFailed = (): PlaygroundAnalysisAction<SectionsState['suggested_questions']> => {
  return {
    type: ACTIONS.SUGGESTED_QUESTIONS_FAILED,
  };
};

export const instructionsInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['instructions']> => {
  return {
    type: inProgress ? ACTIONS.INSTRUCTIONS_PENDING : ACTIONS.INSTRUCTIONS_COMPLETED,
  };
};

export const instructionsSuccess = (data: Instructions): PlaygroundAnalysisAction<SectionsState['instructions']> => {
  return {
    type: ACTIONS.INSTRUCTIONS_SUCCESS,
    payload: { data },
  };
};

export const instructionsFailed = (): PlaygroundAnalysisAction<SectionsState['instructions']> => {
  return {
    type: ACTIONS.INSTRUCTIONS_FAILED,
  };
};

export const researchPlanInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['research_plan']> => {
  return {
    type: inProgress ? ACTIONS.RESEARCH_PLAN_PENDING : ACTIONS.RESEARCH_PLAN_COMPLETED,
  };
};

export const researchPlanSuccess = (
  data?: ResearchPlanParts
): PlaygroundAnalysisAction<SectionsState['research_plan']> => {
  return {
    type: ACTIONS.RESEARCH_PLAN_SUCCESS,
    payload: { data },
  };
};

export const researchPlanFailed = (): PlaygroundAnalysisAction<SectionsState['research_plan']> => {
  return {
    type: ACTIONS.RESEARCH_PLAN_FAILED,
  };
};

export const predictionInProgress = (
  inProgress = true
): PlaygroundAnalysisAction<SectionsState['market_prediction']> => {
  return {
    type: inProgress ? ACTIONS.PREDICTIONS_PENDING : ACTIONS.PREDICTIONS_COMPLETED,
  };
};

export const predictionSuccess = (data: string): PlaygroundAnalysisAction<SectionsState['market_prediction']> => {
  return {
    type: ACTIONS.PREDICTIONS_SUCCESS,
    payload: { data },
  };
};

export const predictionFailed = (): PlaygroundAnalysisAction<SectionsState['market_prediction']> => {
  return {
    type: ACTIONS.PREDICTIONS_FAILED,
  };
};

export const marketSizeInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['market_size']> => {
  return {
    type: inProgress ? ACTIONS.MARKET_SIZE_PENDING : ACTIONS.MARKET_SIZE_COMPLETED,
  };
};

export const marketSizeSuccess = (data: string): PlaygroundAnalysisAction<SectionsState['market_size']> => {
  return {
    type: ACTIONS.MARKET_SIZE_SUCCESS,
    payload: { data },
  };
};

export const marketSizeFailed = (): PlaygroundAnalysisAction<SectionsState['market_size']> => {
  return {
    type: ACTIONS.MARKET_SIZE_FAILED,
  };
};

export const sourcesInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['sources']> => {
  return {
    type: inProgress ? ACTIONS.SOURCES_PENDING : ACTIONS.SOURCES_COMPLETED,
  };
};

export const sourcesSuccess = (data: Source[]): PlaygroundAnalysisAction<SectionsState['sources']> => {
  return {
    type: ACTIONS.SOURCES_SUCCESS,
    payload: { data },
  };
};

export const sourcesFailed = (): PlaygroundAnalysisAction<SectionsState['sources']> => {
  return {
    type: ACTIONS.SOURCES_FAILED,
  };
};

export const internalSourcesInProgress = (
  inProgress = true
): PlaygroundAnalysisAction<SectionsState['internal_sources']> => {
  return {
    type: inProgress ? ACTIONS.INTERNAL_SOURCES_PENDING : ACTIONS.INTERNAL_SOURCES_COMPLETED,
  };
};

export const internalSourcesSuccess = (
  data: InternalSource[]
): PlaygroundAnalysisAction<SectionsState['internal_sources']> => {
  return {
    type: ACTIONS.INTERNAL_SOURCES_SUCCESS,
    payload: { data },
  };
};

export const internalSourcesFailed = (): PlaygroundAnalysisAction<SectionsState['internal_sources']> => {
  return {
    type: ACTIONS.INTERNAL_SOURCES_FAILED,
  };
};

export const summaryInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['summary']> => {
  return {
    type: inProgress ? ACTIONS.SUMMARY_PENDING : ACTIONS.SUMMARY_COMPLETED,
  };
};

export const summarySuccess = (data: string): PlaygroundAnalysisAction<SectionsState['summary']> => {
  return {
    type: ACTIONS.SUMMARY_SUCCESS,
    payload: { data },
  };
};

export const summaryFailed = (): PlaygroundAnalysisAction<SectionsState['summary']> => {
  return {
    type: ACTIONS.SUMMARY_FAILED,
  };
};

export const valueChainInProgress = (inProgress = true): PlaygroundAnalysisAction<SectionsState['value_chain']> => {
  return {
    type: inProgress ? ACTIONS.VALUE_CHAIN_PENDING : ACTIONS.VALUE_CHAIN_COMPLETED,
  };
};

export const valueChainSuccess = (data: ValueChain): PlaygroundAnalysisAction<SectionsState['value_chain']> => {
  return {
    type: ACTIONS.VALUE_CHAIN_SUCCESS,
    payload: { data },
  };
};

export const valueChainFailed = (): PlaygroundAnalysisAction<SectionsState['value_chain']> => {
  return {
    type: ACTIONS.VALUE_CHAIN_FAILED,
  };
};
