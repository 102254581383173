import { omit } from 'underscore';

import { ViewType } from '../types';

const computeViewTypeScore = (viewType: ViewType) => {
  const screens = omit(viewType, ['_id', 'name', 'isDefault']);

  const score = Object.values(screens).reduce((score, currentConfig) => {
    const allowedScreens = Object.values(currentConfig).filter((visible) => visible);

    return score + (allowedScreens.length ? allowedScreens.length * 10 + 100 : 0);
  }, 0);

  return score;
};

const compareViewTypes = (viewType1: ViewType, viewType2: ViewType) =>
  computeViewTypeScore(viewType1) - computeViewTypeScore(viewType2);

export const sortViewTypes = (viewTypes: ViewType[]) => {
  return viewTypes.sort(compareViewTypes);
};

export const viewDrawerIsCollapsed = ({
  homeVisibility,
  modelVisibility,
  researchVisibility,
  scenariosVisibility,
}: ViewType) => {
  const home = omit(homeVisibility, 'playground');

  return [
    ...Object.values(home),
    ...Object.values(modelVisibility),
    ...Object.values(researchVisibility),
    ...Object.values(scenariosVisibility),
  ].every((val) => !val);
};
