export enum ACTIONS {
  'INSTRUCTIONS_PENDING' = 'INSTRUCTIONS_PENDING',
  'INSTRUCTIONS_COMPLETED' = 'INSTRUCTIONS_COMPLETED',
  'INSTRUCTIONS_SUCCESS' = 'INSTRUCTIONS_SUCCESS',
  'INSTRUCTIONS_FAILED' = 'INSTRUCTIONS_FAILED',

  'RESEARCH_PLAN_PENDING' = 'RESEARCH_PLAN_PENDING',
  'RESEARCH_PLAN_COMPLETED' = 'RESEARCH_PLAN_COMPLETED',
  'RESEARCH_PLAN_SUCCESS' = 'RESEARCH_PLAN_SUCCESS',
  'RESEARCH_PLAN_FAILED' = 'RESEARCH_PLAN_FAILED',

  'SOURCES_PENDING' = 'SOURCES_PENDING',
  'SOURCES_COMPLETED' = 'SOURCES_COMPLETED',
  'SOURCES_SUCCESS' = 'SOURCES_SUCCESS',
  'SOURCES_FAILED' = 'SOURCES_FAILED',

  'INTERNAL_SOURCES_PENDING' = 'INTERNAL_SOURCES_PENDING',
  'INTERNAL_SOURCES_COMPLETED' = 'INTERNAL_SOURCES_COMPLETED',
  'INTERNAL_SOURCES_SUCCESS' = 'INTERNAL_SOURCES_SUCCESS',
  'INTERNAL_SOURCES_FAILED' = 'INTERNAL_SOURCES_FAILED',

  'SUMMARY_PENDING' = 'SUMMARY_PENDING',
  'SUMMARY_COMPLETED' = 'SUMMARY_COMPLETED',
  'SUMMARY_SUCCESS' = 'SUMMARY_SUCCESS',
  'SUMMARY_FAILED' = 'SUMMARY_FAILED',

  'COMPANIES_PENDING' = 'COMPANIES_PENDING',
  'COMPANIES_COMPLETED' = 'COMPANIES_COMPLETED',
  'COMPANIES_SUCCESS' = 'COMPANIES_SUCCESS',
  'COMPANIES_FAILED' = 'COMPANIES_FAILED',

  'VALUE_CHAIN_PENDING' = 'VALUE_CHAIN_PENDING',
  'VALUE_CHAIN_COMPLETED' = 'VALUE_CHAIN_COMPLETED',
  'VALUE_CHAIN_SUCCESS' = 'VALUE_CHAIN_SUCCESS',
  'VALUE_CHAIN_FAILED' = 'VALUE_CHAIN_FAILED',

  'CONNECTIONS_PENDING' = 'CONNECTIONS_PENDING',
  'CONNECTIONS_COMPLETED' = 'CONNECTIONS_COMPLETED',
  'CONNECTIONS_SUCCESS' = 'CONNECTIONS_SUCCESS',
  'CONNECTIONS_FAILED' = 'CONNECTIONS_FAILED',

  'MARKET_SIZE_PENDING' = 'MARKET_SIZE_PENDING',
  'MARKET_SIZE_COMPLETED' = 'MARKET_SIZE_COMPLETED',
  'MARKET_SIZE_SUCCESS' = 'MARKET_SIZE_SUCCESS',
  'MARKET_SIZE_FAILED' = 'MARKET_SIZE_FAILED',

  'PREDICTIONS_PENDING' = 'PREDICTIONS_PENDING',
  'PREDICTIONS_COMPLETED' = 'PREDICTIONS_COMPLETED',
  'PREDICTIONS_SUCCESS' = 'PREDICTIONS_SUCCESS',
  'PREDICTIONS_FAILED' = 'PREDICTIONS_FAILED',

  'SUGGESTED_QUESTIONS_PENDING' = 'SUGGESTED_QUESTIONS_PENDING',
  'SUGGESTED_QUESTIONS_COMPLETED' = 'SUGGESTED_QUESTIONS_COMPLETED',
  'SUGGESTED_QUESTIONS_SUCCESS' = 'SUGGESTED_QUESTIONS_SUCCESS',
  'SUGGESTED_QUESTIONS_FAILED' = 'SUGGESTED_QUESTIONS_FAILED',

  'CLEAR_ANALYSIS' = 'CLEAR_ANALYSIS',
}

export interface PlaygroundAnalysisAction<T> {
  type: ACTIONS;
  payload?: Partial<T>;
}
