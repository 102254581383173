const ROUTE_KEYS = {
  SIGN_IN: '/signin',
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',

  // Research Route keys
  RESEARCH: '/research',
  ECOSYSTEM: '/ecosystem',
  ECOSYSTEM_COMPANY_DETAILS: '/ecosystem/:companyId',
  PMF: '/pmf',
  COMPANY_ANCHOR_ANALYSIS: '/companyAnchorAnalysis',
  RESEARCH_PLAYGROUND: '/playground',
  // Ecosystem company detail child routes
  COMPANY_DETAIL_OVERVIEW: '/general',
  COMPANY_DETAIL_CONNECTED_SIGNAL: '/connectedSignal',
  COMPANY_DETAIL_SINGALS_PER_ASPECTS: '/signalsPerAspects',
  COMPANY_DETAIL_TOTAL_SIGNALS: '/totalSignals',
  COMPANY_DETAIL_MENTIONS: '/mentions',
  COMPANY_DETAIL_SIMILAR_COMPANIES: '/similarCompanies',
  COMPANY_DETAIL_RECOMMENDED_SIGNAL: '/recommendedSignals',
  // Research Route keys ends here

  // Model Route keys
  MODEL: '/model',
  MODEL_VALUE_CHAIN: '/value-chain',
  MODEL_EXPANDED_NOTE: '/expandedNote',
  MODEL_AGGRIGATED_NOTE: '/aggregatedNote',
  MODEL_ANCHOR_LIST: '/anchorList',
  SUMMARY: '/summary',
  MODEL_ASPECTS_CANVAS: '/canvas',
  MODEL_NEW_SCREEN: '/exploration',
  // Model Route keys ends here

  // Scenarios Route keys
  SCENARIO: '/scenarios',
  SCENARIO_LIST: '/list',
  SCENARIO_PORTFOLIO_MATRIX: '/portfolioMatrix',
  SCENARIO_CANVAS: '/canvas',
  SCENARIO_STRATEGY_MAP: '/strategyMap',
  SCENARIO_STRATEGY_MAP_SETTINGS: '/settings',
  SCENARIO_MARKET_MAP_ANALYSIS: '/marketMapAnalysis',
  SCENARIO_ANALYSIS: '/scenario-analysis',
  // Scenarios Route keys ends here

  // Global Settings Route keys
  SETTINGS: '/settings',
  PROJECT_SETTINGS: '/projectSetting',
  SCENARIO_GENERATION_SETTINGS: '/scenariosSettings',
  COLLABORATORS_SETTINGS: '/collaborators',
  STRATEGY_SETTINGS: '/strategy',
  AI_SETTINGS: '/aiSettings',
  ECOSYSTEM_SETTINGS: '/ecosystemSettings',
  DEFAULT_WORKSPACE: '/defaultWorkspace',
  PROJECT_DOMAINS_LIST: '/projectDomains',
  // Global Settings Route keys

  // Project level search start here
  SEARCH: '/search',
  SEARCH_INPUT: '/input',
  SEARCH_RESULT: '/results',
  // Project level search end here

  // Un authenticated route
  SIGN_UP: '/signup',
  FORGET_PASS: '/forgotPassword',
  RESET_PASS: '/resetPassword/:resetToken',
  ACCEPT_INVITE: '/invite/:userId/signup',

  // home routes
  HOME: '/home',
  HOME_DASHBOARD: '/dashboard',
  HOME_STATIC_REPORT: '/static-report',
  HOME_REPORTS_LIST: '/reports',
  HOME_AI_GENERATED_ANALYSIS: '/ai_generated_analysis',
  HOME_REPORTS_EDITOR: '/reports/:id',
  HOME_RESOURCE_ANALYSIS: '/resource_analysis',
  RESEARCH_HOME: '/research',
  DATASOURCE_HOME: '/dataSource',
  AI_GENERATED_INSIGHTS: '/ai-insights',

  // home routes
  NEWPROJECT: '/createProject',

  // Admin routes
  ADMIN: '/admin',
  ADMIN_PROJECTS: '/projects',
  ADMIN_USERS: '/users',
  ADMIN_VIEW_TYPE: '/view_type',
  ADMIN_ACTIVITY_LOGS: '/activity_logs',

  // user settings
  USER_SETTINGS: '/user-settings',
  USER_APPEARANCE_SETTINGS: '/appearance',
};

export const QUERY_PARAMS = {
  ECOSYSTEM: { page: 1, pageSize: 20, sorting: 'is_tagged', sortOrder: 'desc', tag: '' },
  PROJECTS: { projectId: '' },
  SEARCH: { text: '', type: '', group: '' },
  COMPANY_NAVIGATION_PARAMS: 'companyNavigationParams',
  SCENARIO_MARKET_MAP: {
    page: 'page',
    tag: 'tag',
  },
};

export const UN_AUTH_ROUTES: string[] = [
  ROUTE_KEYS.SIGN_IN,
  ROUTE_KEYS.SIGN_UP,
  ROUTE_KEYS.FORGET_PASS,
  ROUTE_KEYS.RESET_PASS,
  ROUTE_KEYS.ACCEPT_INVITE,
];

export default ROUTE_KEYS;
