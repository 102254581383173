import { combineReducers } from 'redux';

import { ACTIONS, PlaygroundAnalysisAction, SectionsState, StateSectionNames } from '../../types';

const initialState: SectionsState = {
  companies: { data: [], error: false, loading: false },
  connections: { data: [], error: false, loading: false },
  instructions: { data: { instructions: [] }, error: false, loading: false },
  market_prediction: { data: '', error: false, loading: false },
  market_size: { data: '', error: false, loading: false },
  sources: { data: [], error: false, loading: false },
  internal_sources: { data: [], error: false, loading: false },
  summary: { data: '', error: false, loading: false },
  value_chain: { data: [], error: false, loading: false },
  suggested_questions: { data: [], error: false, loading: false },
  research_plan: { data: { entities: {}, value_chain_phrases: {} }, error: false, loading: false },
};

const companiesReducer = (
  state = initialState.companies,
  action: PlaygroundAnalysisAction<SectionsState['companies']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.companies;
    case ACTIONS.COMPANIES_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.COMPANIES_FAILED:
      return { ...initialState.companies, error: true };
    case ACTIONS.COMPANIES_PENDING:
      return { ...state, loading: true };
    case ACTIONS.COMPANIES_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.companies.data,
      };

    default:
      return state;
  }
};

const connectionsReducer = (
  state = initialState.connections,
  action: PlaygroundAnalysisAction<SectionsState['connections']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.connections;
    case ACTIONS.CONNECTIONS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.CONNECTIONS_FAILED:
      return { ...initialState.connections, error: true };
    case ACTIONS.CONNECTIONS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.CONNECTIONS_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.connections.data,
      };

    default:
      return state;
  }
};

const SuggestedQuestionsReducer = (
  state = initialState.suggested_questions,
  action: PlaygroundAnalysisAction<SectionsState['suggested_questions']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.suggested_questions;
    case ACTIONS.SUGGESTED_QUESTIONS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.SUGGESTED_QUESTIONS_FAILED:
      return { ...initialState.suggested_questions, error: true };
    case ACTIONS.SUGGESTED_QUESTIONS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.SUGGESTED_QUESTIONS_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.suggested_questions.data,
      };

    default:
      return state;
  }
};

const instructionsReducer = (
  state = initialState.instructions,
  action: PlaygroundAnalysisAction<SectionsState['instructions']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.instructions;
    case ACTIONS.INSTRUCTIONS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.INSTRUCTIONS_FAILED:
      return { ...initialState.instructions, error: true };
    case ACTIONS.INSTRUCTIONS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.instructions.data,
      };

    default:
      return state;
  }
};

const researchPartsReducer = (
  state = initialState.research_plan,
  action: PlaygroundAnalysisAction<SectionsState['research_plan']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.research_plan;
    case ACTIONS.RESEARCH_PLAN_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.RESEARCH_PLAN_FAILED:
      return { ...initialState.research_plan, error: true };
    case ACTIONS.RESEARCH_PLAN_PENDING:
      return { ...state, loading: true };
    case ACTIONS.RESEARCH_PLAN_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.research_plan.data,
      };

    default:
      return state;
  }
};

const marketSizeReducer = (
  state = initialState.market_size,
  action: PlaygroundAnalysisAction<SectionsState['market_size']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.market_size;
    case ACTIONS.MARKET_SIZE_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.MARKET_SIZE_FAILED:
      return { ...initialState.market_size, error: true };
    case ACTIONS.MARKET_SIZE_PENDING:
      return { ...state, loading: true };
    case ACTIONS.MARKET_SIZE_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.market_size.data,
      };

    default:
      return state;
  }
};

const predictionReducer = (
  state = initialState.market_prediction,
  action: PlaygroundAnalysisAction<SectionsState['market_prediction']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.market_prediction;
    case ACTIONS.PREDICTIONS_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.PREDICTIONS_FAILED:
      return { ...initialState.market_prediction, error: true };
    case ACTIONS.PREDICTIONS_PENDING:
      return { ...state, loading: true };
    case ACTIONS.PREDICTIONS_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.market_prediction.data,
      };

    default:
      return state;
  }
};

const sourcesReducer = (state = initialState.sources, action: PlaygroundAnalysisAction<SectionsState['sources']>) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.sources;
    case ACTIONS.SOURCES_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.SOURCES_FAILED:
      return { ...initialState.sources, error: true };
    case ACTIONS.SOURCES_PENDING:
      return { ...state, loading: true };
    case ACTIONS.SOURCES_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.sources.data,
      };

    default:
      return state;
  }
};

const internalSourcesReducer = (
  state = initialState.internal_sources,
  action: PlaygroundAnalysisAction<SectionsState['internal_sources']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.internal_sources;
    case ACTIONS.INTERNAL_SOURCES_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.INTERNAL_SOURCES_FAILED:
      return { ...initialState.internal_sources, error: true };
    case ACTIONS.INTERNAL_SOURCES_PENDING:
      return { ...state, loading: true };
    case ACTIONS.INTERNAL_SOURCES_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.internal_sources.data,
      };

    default:
      return state;
  }
};

const summaryReducer = (state = initialState.summary, action: PlaygroundAnalysisAction<SectionsState['summary']>) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.summary;
    case ACTIONS.SUMMARY_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.SUMMARY_FAILED:
      return { ...initialState.summary, error: true };
    case ACTIONS.SUMMARY_PENDING:
      return { ...state, loading: true };
    case ACTIONS.SUMMARY_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.summary.data,
      };

    default:
      return state;
  }
};

const valueChainReducer = (
  state = initialState.value_chain,
  action: PlaygroundAnalysisAction<SectionsState['value_chain']>
) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ANALYSIS:
      return initialState.value_chain;
    case ACTIONS.VALUE_CHAIN_COMPLETED:
      return { ...state, loading: false };
    case ACTIONS.VALUE_CHAIN_FAILED:
      return { ...initialState.value_chain, error: true };
    case ACTIONS.VALUE_CHAIN_PENDING:
      return { ...state, loading: true };
    case ACTIONS.VALUE_CHAIN_SUCCESS:
      return {
        ...state,
        error: false,
        data: action.payload?.data ?? initialState.value_chain.data,
      };

    default:
      return state;
  }
};

const analysisReducer = combineReducers<SectionsState>({
  companies: companiesReducer,
  connections: connectionsReducer,
  instructions: instructionsReducer,
  market_prediction: predictionReducer,
  market_size: marketSizeReducer,
  sources: sourcesReducer,
  summary: summaryReducer,
  value_chain: valueChainReducer,
  suggested_questions: SuggestedQuestionsReducer,
  internal_sources: internalSourcesReducer,
  research_plan: researchPartsReducer,
});

export const reducer = (
  state = initialState,
  action: PlaygroundAnalysisAction<SectionsState[StateSectionNames]>
): SectionsState => {
  return analysisReducer(state, action);
};
